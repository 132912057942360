<template>
  <!-- 页面name：查看 -->
  <div class="contain" v-loading.fullscreen.lock="loadingDetail">
    <!-- <div class="con-header">
      <span>{{metaTitle}}</span>
    </div> -->
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      :content="metaTitle"
    >
      <template slot="title"> <i class="el-icon-arrow-left"></i>返回 </template>
    </el-page-header>
    <div class="con-header-little">
      <i class="el-icon-caret-bottom" style="margin-right: 0.52083vw"></i>
      <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="" /> -->
      <span>基本信息</span>
    </div>
    <!-- <div class=""> -->
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="ruleForm"
      :label-width="labelWidth"
    >
      <el-form-item label="目标类型：" prop="targetType">
        <span class="labelVal" v-if="ruleForm.targetType">
          {{ ruleForm.targetType.label }}
        </span>
      </el-form-item>
      <el-form-item label="目标名称：" prop="targetName">
        <el-input
          style="width: 100%"
          disabled
          v-model="ruleForm.targetName"
        ></el-input>
      </el-form-item>
      <el-form-item label="目标值：" prop="targetValue">
        <el-input
          style="width: 100%"
          :disabled="!isEdit"
          v-model="ruleForm.targetValue"
        ></el-input>
      </el-form-item>
      <el-form-item label="计量单位：" prop="targetUnit">
        <el-input
          style="width: 100%"
          disabled
          v-model="ruleForm.targetUnit"
        ></el-input>
      </el-form-item>
      <el-form-item label="责任部门：" prop="deptName">
        <el-cascader
          style="width: 100%"
          :options="deptLabelList"
          :props="deptProps"
          disabled
          v-model="ruleForm.deptLabel"
          :show-all-levels="false"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="截止日期：" prop="endTime">
        <!-- {{ruleForm.endTime}} -->
        <el-date-picker
          style="width: 100%"
          disabled
          v-model="ruleForm.endTime"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="日历图标：" prop="iconPath">
        <el-popover
          placement="right-start"
          width="494"
          disabled
          popper-class="opIocn"
          @show="showIcon"
          trigger="click"
          v-model="dialogVisible"
        >
          <el-button slot="reference" disabled type="text">
            <span v-if="ruleForm.iconPath == ''">选择图标</span>
            <span v-else>更换图标</span>
          </el-button>
          <div slot="reference" v-if="ruleForm.iconPath" class="iconPathWrap">
            <img :src="ruleForm.iconPath.scheduleIcon" alt="" />
          </div>
          <div>
            <div class="iconTitle">
              <span>图标库</span>
              <span class="el-icon-close" @click="dialogVisible = false"></span>
            </div>
            <div class="dialogDiv">
              <div
                :class="
                  iconPathInfo.id == item.id
                    ? 'iconPathWrap active'
                    : 'iconPathWrap'
                "
                @click="chooseIconPath(item)"
                v-for="item in iconPathList"
                :key="item.id"
              >
                <img :src="item.scheduleIcon" alt="" />
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="chooseIconPathBtn"
                >确 定</el-button
              >
            </span>
          </div>
        </el-popover>
      </el-form-item>

      <el-form-item label="描述：" prop="targetDescript">
        <!-- {{ruleForm.targetDescript}} -->
        <el-input
          type="textarea"
          rows="4"
          disabled
          v-model="ruleForm.targetDescript"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">目标分解</el-divider>
      <el-form-item label="分解维度：" prop="type">
        <!-- {{ruleForm.type.label}} -->
        <el-radio-group
          v-if="ruleForm.type"
          disabled
          v-model="ruleForm.type.value"
        >
          <el-radio
            v-for="(item, index) in dictList.target_part_type"
            :key="index"
            :label="item.value"
            :value="item.value"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="createResolutionList"
          >新增</el-button
        >
      </el-form-item>

      <el-form-item style="width: 100%">
        <el-table
          :header-cell-style="{
            background: '#F1F4F9',
            color: '#5E6D9B',
            fontWeight: '400',
            'text-align': 'center',
          }"
          style="
            width: 100%;
            margin-bottom: 1.04167vw;
            margin-top: 1.04167vw;
            min-height: calc(100% - 5.52083vw);
            overflow-y: scroll;
            overflow: visible;
          "
          :data="ruleForm.childList"
          border
        >
          <el-table-column fixed prop="date" label="分解对象">
            <template slot-scope="scope">
              <!-- 分解对象，园校-->
              <el-cascader
                filterable
                v-if="ruleForm.type.value == 3"
                v-model="scope.row.companyLabel"
                @change="changeSchool(scope.row)"
                :options="headquartersObjList"
                :disabled="scope.row.isFinish"
                :props="optionProps"
              ></el-cascader>
              <!-- 分解对象，分公司-->
              <el-select
                filterable
                :disabled="scope.row.isFinish"
                v-else-if="ruleForm.type.value == 2"
                v-model="scope.row.companyLabel"
                placeholder="请选择"
                @change="changeCompany(scope.row)"
              >
                <el-option
                  v-for="(item, index) in companyList"
                  :key="index"
                  :label="item.name"
                  :value="item.label"
                ></el-option>
              </el-select>
              <!-- 分解对象，总部 -->
              <el-cascader
                filterable
                :disabled="scope.row.isFinish"
                v-else
                :options="deptLabelList"
                :props="deptProps"
                v-model="scope.row.companyLabel"
                :show-all-levels="false"
                @change="changeDept(scope.row)"
                clearable
              ></el-cascader>
            </template>
          </el-table-column>
          <el-table-column prop="targetValue" label="目标值">
            <template slot-scope="scope" >
              <el-input type="number" v-if="!scope.row.isFinish" min="1" v-model="scope.row.targetValue" placeholder="请输入目标值"></el-input>
              <span v-else>{{scope.row.targetValue}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="province" label="指定岗位/人">
            <template slot-scope="scope">
              <el-cascader
                :options="scope.row.userPostList"
                :props="deptPropsNew"
                v-model="scope.row.dutyUserList"
                :show-all-levels="false"
                :disabled="!isEdit"
                @change="changeUserPost(scope.row)"
                clearable
              ></el-cascader>
            </template>
          </el-table-column>
          <el-table-column prop="province" label="操作">
            <template slot-scope="scope" >
              <div v-show="!scope.row.isFinish">
                <!-- <el-button v-if="scope.row.isSave == 0" type="text" size="small" @click="editList(1,scope.row)">保存</el-button>
                <el-button v-else type="text" size="small" @click="editList(2,scope.row)">编辑</el-button> -->
                <el-button type="text" size="small" @click="delList(scope.$index)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item v-if="isEdit">
        <el-button class="reset" @click="goBack">取消</el-button>
        <el-button type="primary" @click="saveJob">保存</el-button>
      </el-form-item>
    </el-form>

    <!-- </div> -->
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile";
import onlinePreview from "@/components/onlinePreview/index";
export default {
  data() {
    return {
      loadingDetail: false,
      lookId: "",
      ruleForm: {},
      fileAttachment: {},
      onlinePreviewVisible: false,
      dictList: {
        target_part_type: [],
        target_type: [],
      },
      deptLabelList: [],
      isEdit: false,
      deptProps: {
        checkStrictly: true,
        label: "name",
        value: "label",
        children: "child",
      },
      deptPropsNew: {
        checkStrictly: false,
        label: "name",
        value: "label",
        children: "child",
        multiple: true 
      },
      childList: [],
      companyList: [],
      optionProps: {
        value: "label",
        label: "name",
        children: "child",
      },
      headquartersObjList: [],
      metaTitle: "",
      loadingJob: false,

      labelWidth: "120px",
      dialogVisible: false,
      iconPathList: [],
      iconPathInfo: {},
      oldTargetValue:0
    };
  },
  components: {
    onlinePreview,
  },
  mounted() {
    this.lookId = this.$route.query.lookId;
    // this.tableLoading = true;
    this.init();
    if (this.$route.query.type == 2) {
      this.isEdit = true;
      this.metaTitle = "编辑目标";
    } else {
      this.metaTitle = "目标详情";
    }
    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList")
      ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
      : null;
    if (chooseMenuInfoList) {
      chooseMenuInfoList.map((item) => {
        if (item.path == this.$route.path) {
          item.meta.title = this.metaTitle;
        }
      });
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList));
      breadCrumbList.shift();
      // console.log(breadCrumbList)
      this.$store.commit("common/getBreadcrumbList", breadCrumbList || []);
    }

    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }
  },
  created() {
    this.queryDict();
    this.getDepartList();
    this.initIconData();
    // this.getDecomposeObjectList()
  },
  computed: {
    unassigned:function(){
      let numb = 0
      if(this.ruleForm.childList){
        this.ruleForm.childList.forEach(e=>{
          numb+=Number(e.targetValue)
        })
      }
      return this.ruleForm.targetValue-numb < 0 ? 0 : this.ruleForm.targetValue-numb 
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getCompanyDept() {
      this.$api
        .getCompanyDept({
          type: "NODE_ORG",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.companyList = res.data.data;
          }
        });
    },
    getDecomposeObjectList() {
      this.$api.getDecomposeObjectList().then((res) => {
        if (res.data.code == 0) {
          this.headquartersObjList = res.data.data;
        }
      });
    },
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },
    // 修改总部
    changeDept(val){
      console.log(val)
      if(val.companyLabel){
        val.dutyUserList = []
        this.getUserPostForTree(val.companyLabel[val.companyLabel.length-1],val.index)
      }
    },
    // 修改分公司
    changeCompany(val){
      if(val.companyLabel){
        val.dutyUserList = []
        this.getUserPostForTree(val.companyLabel,val.index)
      }
    },
    // 修改园校
    changeSchool(val){
      console.log(val,val.companyLabel[val.companyLabel.length-1],val.index);
      if(val.companyLabel){
        val.dutyUserList = []
        this.getUserPostForTree(val.companyLabel[val.companyLabel.length-1],val.index)
      }
    },
    // 新增分解对象
    createResolutionList(){
      this.ruleForm.childList.push({
        isFinish:false,
        // isSave:1,
        dutyUserList:[], //责任人
        targetValue:'', //目标值
        type:this.ruleForm.type.value, //分解维度类型
        companyLabel:'', //所属分公司
        campusLabel:'',//所属园区
        level:1,
        dutyUserName:[],
        userPostList:[],
        index:this.ruleForm.childList.length
      })
    },
    // 编辑分解对象
    // editList(type,item){
    //   if(item.companyLabel == ''){
    //     this.$message.error('请选择分解对象')
    //     return
    //   }
    //   if(parseInt(item.targetValue)>parseInt(this.ruleForm.targetValue)){
    //     this.$message.error('单个分解对象的目标值必须小于总目标')
    //     return 
    //   }
    //   if(item.targetValue == '' || item.targetValue <= 0){
    //     this.$message.error('目标值不能为空且必须大于0')
    //     return
    //   }
    //   if(item.dutyUser == ''){
    //     this.$message.error('请选择指定岗位/人')
    //     return
    //   }
    //   if(type == 1){
    //     item.isSave = 1
    //   }else if(type == 2){
    //     item.isSave = 0
    //   }
    // },
    // 删除分解对象
    delList(index){
      this.ruleForm.childList.splice(index,1)
    },
    // 修改岗位
    changeUserPost(val){
      var platList= this.platFn(val.userPostList)
      let result = platList.filter(item => val.dutyUserList.some(e => e[1] === item.label));
      val.dutyUserName = []
      result.map(item=>{
        val.dutyUserName.push(item.name)
      })
      console.log(val.dutyUserName);
    },
    platFn(list) {
      let res = []
      list.map(item =>{
        if(item.child instanceof Array && item.child.length > 0){
          res = res.concat(...item.child)
        }
      })
      return res
    },
    platChildListFn(list) {
      let res = []
      list.map(item =>{
        if(item.childList instanceof Array && item.childList.length > 0){
          res = res.concat(...item.childList)
        }
      })
      return res
    },
    /** 初始化 */
    init() {
      let params = {
        id: this.lookId,
      };
      this.loadingDetail = true;
      this.$api.getTargetInfo(params).then((res) => {
        // this.tableLoading = false;
        if (res.data.code == 0) {
          this.ruleForm = JSON.parse(JSON.stringify(res.data.data));
          if (this.ruleForm.type.value == 3) {
            this.getDecomposeObjectList();
          } else if (this.ruleForm.type.value == 2) {
            this.getCompanyDept();
          }
          if(this.ruleForm.type.value == 3){
            let childList = this.platChildListFn(this.ruleForm.childList)
            childList.forEach((e,index)=>{
              e.isFinish = true
              e.companyLabel = [e.companyLabel,e.campusLabel]
              this.getUserPostForTree(e.campusLabel, index);
            })
            console.log(childList);
            this.ruleForm.childList = childList
          }
          this.oldTargetValue = this.ruleForm.targetValue
          this.getIconPath(this.ruleForm.iconPath);
          if (this.ruleForm.childList && this.ruleForm.type.value != 3) {
            this.ruleForm.childList.forEach((e, index) => {
              e.isFinish = true
              // if (this.ruleForm.type.value == 3) {
              //   // e.companyLabel = [e.companyLabel,e.campusLabel]
              //   // this.getUserPostForTree(e.campusLabel, index);
              // } else 
              {
                this.getUserPostForTree(e.companyLabel, index);
              }
            });
            console.log(this.ruleForm.childList);
            this.loadingDetail = false;
          } else {
            this.loadingDetail = false;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loadingDetail = false;
        }
      });
    },
    // 获取责任部门
    getDepartList() {
      this.$api.getDepartList().then((res) => {
        if (res.data.code == "0") {
          // this.deptLabelList = res.data.data
          this.deptLabelList = res.data.data;
          if (this.deptLabelList) {
            this.deptLabelList.forEach((e, index) => {
              e.disabled = true;
              e.label = index;
            });
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    saveJob() {
      if(parseInt(this.ruleForm.targetValue)<parseInt(this.oldTargetValue)){
        this.$message.error("总目标值需大于或者等于原总目标值");
        return
      }
      if(this.unassigned != 0){
        this.$message.error('总目标值不得大于分解之和')
        return
      }
      console.log(this.ruleForm.childList);
      for (let index = 0; index < this.ruleForm.childList.length; index++) {
        const e = this.ruleForm.childList[index];
          if(!e.targetValue){
            this.$message.error('请输入目标值')
            return
          }
          if(!e.companyLabel){
            this.$message.error('请选择分解对象')
            return
          }
          if(!e.dutyUserList){
            this.$message.error('请选择指定岗位/人')
            return
          }
          if(parseInt(e.targetValue)>parseInt(this.ruleForm.targetValue)){
            this.$message.error('单个分解对象的目标值必须小于总目标')
            return 
          }
      }
      let arr = []
      // ruleForm.type 分解类型
      if(this.ruleForm.type.value == 3 && this.ruleForm.childList.length>0){
        // 园校分解
        let parent = []
        this.ruleForm.childList.map((e)=>{
          parent.push(e.companyLabel[0])
        })
        parent = [...new Set(parent)]
        parent.forEach(ele=>{
          let child = []
          let targetValue = 0
          let parentId = ''
          this.ruleForm.childList.forEach(e=>{
              if(e.companyLabel[0] == ele){
                parentId = e.parentId?e.parentId:parentId?parentId:null
                targetValue+=parseInt(e.targetValue)
                let ids = []
                let userObj = []
                let postList = []
                postList = e.dutyUserList.map(ep=>ep[0])
                postList = [...new Set(postList)]
                postList.map(elep=>{
                  let userLabel = []
                  e.dutyUserList.map(ed=>{
                    if(elep == ed[0]){
                      userLabel.push(ed[1])
                    }
                  })
                  userObj.push({
                    postLabel:elep,
                    userLabel:userLabel,
                  })
                })
                child.push({
                  id:e.id?e.id:null,
                  level:3,
                  type:this.ruleForm.type.value,
                  dutyUserArr:userObj,
                  jobArr:postList,
                  targetValue:e.targetValue,
                  companyLabel:e.companyLabel[0],
                  campusLabel:e.companyLabel[1],
                  dutyUserName: Array.isArray(e.dutyUserName)?e.dutyUserName.join('、'):e.dutyUserName,
                })
              }
          })
          arr.push({
            level:2,
            type:this.ruleForm.type.value,
            targetValue:targetValue,
            childList:child,
            companyLabel:ele,
            id:parentId,
          })
        })
      }else if(this.ruleForm.type.value == 2 && this.ruleForm.childList.length>0){
        // 分公司分解
        this.ruleForm.childList.forEach(e=>{
            let userObj = []
            let postList = []
            postList = e.dutyUserList.map(ep=>ep[0])
            postList = [...new Set(postList)]
            postList.map(elep=>{
              let userLabel = []
              e.dutyUserList.map(ed=>{
                if(elep == ed[0]){
                  userLabel.push(ed[1])
                }
              })
              userObj.push({
                postLabel:elep,
                userLabel:userLabel,
              })
            })
            arr.push({
              id:e.id?e.id:null,
              level:2,
              type:this.ruleForm.type.value,
              targetValue:e.targetValue,
              dutyUserArr:userObj,
              jobArr:postList,
              companyLabel:e.companyLabel,
              dutyUserName: Array.isArray(e.dutyUserName)?e.dutyUserName.join('、'):e.dutyUserName,
            })
        })
      }else if(this.ruleForm.type.value == 1 && this.ruleForm.childList.length>0){
        // 总部分解
        this.ruleForm.childList.forEach(e=>{
            let companyLabel
            if(e.companyLabel.length == 2){
              companyLabel = e.companyLabel[1]
            } else if(e.companyLabel.length == 3){
              companyLabel = e.companyLabel[2]
            }
            let userObj = []
            let postList = []
            postList = e.dutyUserList.map(ep=>ep[0])
            postList = [...new Set(postList)]
            postList.map(elep=>{
              let userLabel = []
              e.dutyUserList.map(ed=>{
                if(elep == ed[0]){
                  userLabel.push(ed[1])
                }
              })
              userObj.push({
                postLabel:elep,
                userLabel:userLabel,
              })
            })
            arr.push({
              id:e.id?e.id:null,
              level:2,
              type:this.ruleForm.type.value,
              targetValue:e.targetValue,
              dutyUserArr:userObj,
              jobArr:postList,
              companyLabel:companyLabel,
              dutyUserName: Array.isArray(e.dutyUserName)?e.dutyUserName.join('、'):e.dutyUserName,
            })
        })
      }
      this.loadingDetail = true;
      this.$api
        .updataDutyUser({
          id:this.$route.query.lookId,
          targetValue:this.ruleForm.targetValue,
          childList:arr
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.$message({
              type: "success",
              message: "编辑目标成功!",
              duration: 2000,
              onClose: () => {
                this.loadingDetail = false;
                this.$router.back(-1);
              },
            });
          } else {
            this.loadingDetail = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.loadingDetail = false;
        });
    },
    // 获取岗位/人
    getUserPostForTree(label, index) {
      console.log(index);
      this.$api
        .getUserPostForTree({
          label: label,
        })
        .then((res) => {
          this.loadingDetail = false;
          if (res.data.code == "0") {
            // this.ruleForm.childList[index].userPostList = res.data.data
            // console.log(this.ruleForm.childList[index].userPostList)
            this.$forceUpdate();
            this.$set(
              this.ruleForm.childList[index],
              "userPostList",
              res.data.data
            );
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.loadingDetail = false;
        });
    },
    initUser() {},
    initIconData() {
      this.$api.getAllIconList().then((res) => {
        if (res.data.code == "0") {
          this.iconPathList = res.data.data;
          // console.log(res.data.data)
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    chooseIconPath(item) {
      this.iconPathInfo = item;
    },
    chooseIconPathBtn() {
      this.ruleForm.iconPath = this.iconPathInfo;
      this.$refs.ruleForm.clearValidate("iconPath");
      this.dialogVisible = false;
    },
    showIcon() {
      if (this.ruleForm.iconPath) {
        this.iconPathInfo = this.ruleForm.iconPath;
      } else {
        this.iconPathInfo = "";
      }
    },
    getIconPath(id) {
      this.$api.getIconDetail(id).then((res) => {
        if (res.data.code == "0") {
          console.log(res.data.data);
          this.ruleForm.iconPath = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
/deep/.container .layout-main-wrap .layout-main {
  background: #ffffff;
  height: 100%;
  box-sizing: border-box;
  overflow-x: scroll !important;
  overflow-y: scroll;
}
.contain {
  padding: 34px 30px 30px 30px;

  .con-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    color: #3d3e4f;
  }

  .con-header-little {
    margin: 40px 0 30px 0;
  }

  .con-header-little span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #3d3e4f;
  }

  /deep/.el-form-item__label {
    // line-height: 16px;
  }

  /deep/.el-form-item__content {
    display: flex;
    align-items: center;
    // line-height: 40px;
  }

  /deep/ .el-form-item {
    width: 100%;
    // display: flex;
    // align-items: center;
    margin-bottom: 30px;
  }
  /deep/.el-radio-group {
    height: 40px;
    line-height: 40px;
  }
  .labelVal {
    line-height: 40px;
  }
  .left-label {
    font-size: 16px;
    font-weight: 400;
    color: #606266;
    margin-bottom: 30px;
  }

  .con-fonts {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .file-smallimg {
    width: 14px;
    height: 16px;
    margin-right: 8px;
  }

  .measure-mar {
    margin-bottom: 30px;
  }

  .measure-contain {
    width: 1200px;
    min-height: 104px;

    .middle-content {
      border-bottom: 2px solid #e6ebf3;
    }

    .mea-con-tablehead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 14px;

      .tablehead-times {
        width: 200px;
        display: flex;
        justify-content: center;
      }
      .tablehead-idea {
        flex: 1;
        display: flex;
      }
    }

    .tablehead-backg {
      height: 48px;
      background: #f1f4f9;
      border-radius: 12px 12px 0px 0px;
      padding: 0;
    }

    .tablehead-times-head {
      font-weight: 400;
      color: #5e6d9b;
    }
  }
  .files-look {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .files-button {
      margin-left: 40px;
    }
  }

  /deep/.el-button--text {
    padding: 0;
  }
}
.el-input input {
  width: 100%;
}
.iconTitle {
  display: flex;
  justify-content: space-between;
  padding: 2px 28px 14px;
  align-items: center;
  color: #3d3e4f;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .el-icon-close {
    color: #909399;
    font-size: 14px;
    cursor: pointer;
  }
}
.iconPathWrap {
  width: 50px;
  height: 50px;
  background: #eaf1fc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
/deep/.el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
/deep/.el-dialog__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.dialog-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.dialogDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px;
  box-sizing: border-box;
  .iconPathWrap {
    background: #fff;
    border: 1px solid #fff;
    cursor: pointer;
  }
  .active {
    border: 1px solid #003685;
  }
}
.icon-list {
  display: flex;
  flex-wrap: wrap;
  .icon-item {
    margin-right: 6px;
  }
}
.dialogDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px;
  box-sizing: border-box;
}
</style>

<style>
.opIocn {
  padding: 10px 0;
  z-index: 1900 !important;
}
</style>